<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #description>  
            IPAG (ранее называвшаяся Institut de préparation à l’administration et à la gestion) - это французская частная бизнес-школа, основанная в 1965 году Жаком Рюффом.  Ее кампусы расположены в Париже, Ницце, Лос-Анджелесе и Куньмине. Школа также имеет онлайн-программы магистратуры. 
        </template>
        <template #orientation>
            Bachelor & Master
        </template>
        <template #speciality>
            Finance, business, economy
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="baa">BBA France & UK</option>
                                <option value="bach">Bachelor</option>
                                <option value="gep">Grande École Programme</option>
                                <option value="mba">MBA</option>
                                <option value="io">IPAG Online</option>
                            </select>
                        </div>
                    </div>
                    
                    <Program :url="'/ipag/IPAG_BAA_BROCHURE.pdf'" v-if="this.program === 'baa'" id="baa">
                        <template #title>
                            Бакалавриат - BBA France & UK
                        </template>
                        <template #description>
                           <p>
                                Эта программа рекомендуется для тех, кто рассматривает карьеру за границей или в международной среде. Обучение носит общий характер, но третий год позволяет студентам специализироваться в двух областях, которые они затем могут продолжить, обучаясь на MBA.
                           </p>
                           <p>
                               Все курсы преподаются на английском языке, а среда программы является многокультурной, поскольку BBA IPAG объединяет студентов более 30 национальностей.
                                BBA IPAG также позволяет студентам получить двойную степень на 3-м курсе, одну степень IPAG и степень бакалавра искусств в престижном британском университете, который может открыть путь к степени магистра.
                           </p>
                        </template>
                        <template #duree>
                            3 года
                        </template>
                        <template #language>
                             английский
                        </template>
                        <template #cost>
                            8.900-14.000 евро/год
                        </template>
                        <template #requirements>
                            английский язык уровня B2, досье, интервью.
                        </template>
                        <template #campus>
                             Париж, Ницца, Ноттингем
                        </template>
                        <template #start-time>
                            сентябрь, январь
                        </template>
                    </Program>

                    <Program :url="'/ipag/IPAG_BACHELOR_FR.pdf'" v-if="this.program === 'bach'" id="bach">
                        <template #title>
                            Bachelor
                        </template>
                        <template #description>
                            <p>
                                Степень бакалавра IPAG - это одобренный государством 3-летний диплом. С первого года вы выбираете специализацию и быстро развиваете свои навыки, чтобы через 3 года быть полностью готовым работать на рынке труда.
                            </p>
                        </template>
                        <template #duree>
                            3 года
                        </template>
                        <template #language>
                            французский
                        </template>
                        <template #cost>
                            6.900-7.900 евро/год
                        </template>
                        <template #requirements>
                            французский язык B2, досье, интервью.
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            сентябрь, февраль
                        </template>
                        <template #salary>
                            38.000-42.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/ipag/IPAG_PGE_EN.pdf'" v-if="this.program === 'gep'" id="gep">
                        <template #title>Global BBA - Bachelor in Business Administration</template>
                        <template #description>
                            <p>
                                Программа состоит из двух циклов: первый трехлетний цикл посвящен изучению основ, а второй двухлетний цикл посвящен специализации.
                                Курс предлагает множество международных возможностей, например, в конце первого года все студенты имеют возможность посетить 6-недельную летнюю сессию в Калифорнийском университете в Беркли. Кроме того, программа Grande École также позволяет студентам получить двойную степень в одном из университетов-партнеров в Великобритании, Америке и других странах.
                            </p>
                        </template>
                        <template #duree>
                            от 2-5 лет
                        </template>
                        <template #language>
                            французский и английский
                        </template>
                        <template #cost>
                            9.100-10.100 евро/год 
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж и Ницца
                        </template>
                        <template #start-time>
                            сентябрь и февраль
                        </template>
                        <template #salary>
                            44.000 евро/год
                        </template>
                    </Program>
                    
                    <Program :url="'/ipag/IPAG_MBA.pdf'" v-if="this.program === 'mba'" id="mba">
                        <template #title>TEMA Innovation & Digital Management </template>
                        <template #description>
                            <p>
                                Специализированный MBA IPAG - это международная степень в области делового администрирования (магистр делового администрирования). Программа предназначена для обладателей высшего образования сроком 3 или 4 года.
                                Вместе со студентами всех национальностей вы научитесь управлять командами и пилотными проектами в деловой среде, на которую все больше влияют цифровые и новые технологии.
                            </p>
                        </template>
                        <template #duree>
                            18 месяцев
                        </template>
                        <template #language>
                            английский, французский
                        </template>
                        <template #cost>
                             16,000 евро/год 
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж и Ницца
                        </template>
                        <template #start-time>
                            Сентябрь и январь
                        </template>
                    </Program>

                    <Program :url="'/ipag/IPAG_ONLINE.pdf'" v-if="this.program === 'io'" id="io">
                        <template #title>IPAG Online</template>
                        <template #duree>
                            1-2 года
                        </template>
                        <template #description>
                            <p>
                                IPAG Online в настоящее время предлагает только программы магистратуры (MSc), BAC+ 5 (пять лет послешкольного образования), которые полностью преподаются на английском или французском и английском языках. Это направления, связанные с навыками, которые в настоящее время являются «критическими» на рынке труда, что позволяет гарантировать высокую степень трудоустройства выпускников.
                                Возможность совмещать с работой или прохождение долгосрочной стажировки в компании.
                            </p>
                        </template>
                        <template #language>
                            французский или английский 
                        </template>
                        <template #cost>
                            9.000 евро/год (за 1 год обучения), 12.250 (за 2 года)
                        </template>
                        <template #requirements>
                             французский и английский языки уровня B2, GMAT или TAGE MAGE рекомендуется, досье, интервью.
                        </template>
                        <template #start-time>
                            сентябрь, ноябрь, февраль, апрель
                        </template>
                    </Program>

                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
        <template #campuses>
            <Campus>
                <template #localisation>
                    Париж
                </template>
                <template #description>
                    «исторический» парижский кампус школы идеально расположен в самом центре района Сен-Жермен-де-Пре, в центре культурной и интеллектуальной жизни столицы. С начала 2021 года IPAG Business School Europe открыла свой второй кампус в Париже в 15-м округе: новый кампус площадью 5000 кв.м с медиа-библиотекой, многочисленными учебными комнатами, кафетерием и тренажерным залом. У здания даже есть органический сад и ульи на крыше.
                </template>
            </Campus>

            <Campus>
                <template #localisation>
                    Ницца
                </template>
                <template #description>
                    “между морем и горами”. Бизнес-школа IPAG уже более 30 лет находится в самом сердце Ниццы, на месте бывшего Chambre des Métiers. Кампус принимает более 1000 студентов около 40 национальностей. Среда для учебы идеальна: два больших театра, сад, кафетерий и солнечная терраса.
                </template>
            </Campus>

            <Campus>
                <template #localisation>
                    IPAG Online
                </template>
                <template #description>
                    новое подразделение группы IPAG, специализирующееся на цифровом обучении, сочетает в себе превосходство бизнес-школы с гибкостью дистанционного обучения.
                </template>
            </Campus>
        </template>
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>