<template>
    <Introduction :url="'/brochure/ipag'" :class="'introduction-ipag'">  
        <template #title>
            Ipag
        </template>
        <template #speciality>
            Бизнес
        </template>
        <template #min-duration>
            От 1 года
        </template>
        <template #cost>
            От 8900 €/год
        </template>
        <template #campus>
            Париж, Ницца
        </template>
    </Introduction>  
</template>
<script>
import Introduction from '@/components/schools/slots/Introduction.vue'
export default {
    components:{
        Introduction
    }
}
</script>